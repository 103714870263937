import {
  APP_BASE_HREF,
  AsyncPipe,
  CommonModule,
  PlatformLocation,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SharedRoutingModule } from './shared-routing.module';
import { AddCommissionModalComponent } from './components/add-commission-modal/add-commission-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProposalsComponent } from './components/proposals/proposals.component';
import { LeadFormComponent } from './components/lead-form/lead-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from 'ngx-toastr';
import { HealthFormComponent } from './components/lead-form/health-form/health-form.component';
import { TravelFormComponent } from './components/lead-form/travel-form/travel-form.component';
import { CentralisationFormComponent } from './components/lead-form/centralisation-form/centralisation-form.component';
import { RcHouseholdFormComponent } from './components/lead-form/rc-household-form/rc-household-form.component';
import { VehicleFormComponent } from './components/lead-form/vehicle-form/vehicle-form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LegalProtectionFormComponent } from './components/lead-form/legal-protection-form/legal-protection-form.component';
import { BuildingFormComponent } from './components/lead-form/building-form/building-form.component';
import { ConstructionFormComponent } from './components/lead-form/construction-form/construction-form.component';
import { ThirdPillarFormComponent } from './components/lead-form/third-pillar-form/third-pillar-form.component';
import { AnimalFormComponent } from './components/lead-form/animal-form/animal-form.component';
import { ValuableObjectsFormComponent } from './components/lead-form/valuable-objects-form/valuable-objects-form.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentUploadModalComponent } from './components/document-upload-modal/document-upload-modal.component';
import { GenerateUserPasswordModalComponent } from './components/generate-user-password-modal/generate-user-password-modal.component';
import { UpdateLeadComponent } from './components/update-lead/update-lead.component';
import { AddLeadComponent } from './components/add-lead/add-lead.component';
import { UpdateLeadPageComponent } from './components/update-lead-page/update-lead-page.component';
import { AddLeadPageComponent } from './components/add-lead-page/add-lead-page.component';
import { DocumentRequestModalComponent } from './components/document-request-modal/document-request-modal.component';
import { UpdateDocumentModalComponent } from './components/update-document-modal/update-document-modal.component';
import { UpdatePolicyStatusComponent } from './components/update-policy-status/update-policy-status.component';
import { AssignProductsModalComponent } from './components/assign-products-modal/assign-products-modal.component';
import { InitCommissionButtonComponent } from './components/init-commission-button/init-commission-button.component';
import { CancellationFormComponent } from './components/lead-form/cancellation-form/cancellation-form.component';
import { PartnerNavigationComponent } from './components/partner-navigation/partner-navigation.component';
import { AgentNavigationComponent } from './components/agent-navigation/agent-navigation.component';
import { PartnerActivationModalComponent } from './components/partner-activation-modal/partner-activation-modal.component';
import { AssignPartnerAgentModalComponent } from './components/assign-partner-agent-modal/assign-partner-agent-modal.component';
import { AssignCustomerSupportModalComponent } from './components/assign-customer-support-modal/assign-customer-support-modal.component';
import { MatInputModule } from '@angular/material/input';
import { DuplicateLeadComponent } from './components/duplicate-lead/duplicate-lead.component';
import { DuplicateLeadPageComponent } from './components/duplicate-lead-page/duplicate-lead-page.component';
import { DeleteLeadModalComponent } from './components/delete-lead-modal/delete-lead-modal.component';
import { DeleteProposalModalComponent } from './components/delete-proposal-modal/delete-proposal-modal.component';

//import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
//import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component';
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    AddCommissionModalComponent,
    ProposalsComponent,
    AddLeadComponent,
    HealthFormComponent,
    TravelFormComponent,
    CentralisationFormComponent,
    RcHouseholdFormComponent,
    VehicleFormComponent,
    LegalProtectionFormComponent,
    BuildingFormComponent,
    ConstructionFormComponent,
    ThirdPillarFormComponent,
    AnimalFormComponent,
    ValuableObjectsFormComponent,
    PdfViewerModalComponent,
    DocumentUploadModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateLeadComponent,
    LeadFormComponent,
    AddLeadPageComponent,
    UpdateLeadPageComponent,
    DocumentRequestModalComponent,
    UpdateDocumentModalComponent,
    UpdatePolicyStatusComponent,
    AssignProductsModalComponent,
    InitCommissionButtonComponent,
    CancellationFormComponent,
    PartnerNavigationComponent,
    AgentNavigationComponent,
    PartnerActivationModalComponent,
    AssignPartnerAgentModalComponent,
    AssignCustomerSupportModalComponent,
    DuplicateLeadComponent,
    DuplicateLeadPageComponent,
    DeleteLeadModalComponent,
    DeleteProposalModalComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ToastrModule,
    MatAutocompleteModule,
    NgbModalModule,
    PdfViewerModule,
    AsyncPipe,
    MatInputModule,
  ],
  exports: [
    AddLeadComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    AddCommissionModalComponent,
    ProposalsComponent,
    PdfViewerModalComponent,
    DocumentUploadModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateLeadComponent,
    AddLeadPageComponent,
    UpdateLeadPageComponent,
    DocumentRequestModalComponent,
    UpdateDocumentModalComponent,
    UpdatePolicyStatusComponent,
    AssignProductsModalComponent,
    InitCommissionButtonComponent,
    PartnerNavigationComponent,
    AgentNavigationComponent,
    PartnerActivationModalComponent,
    AssignPartnerAgentModalComponent,
    AssignCustomerSupportModalComponent,
    DuplicateLeadComponent,
    DeleteLeadModalComponent,
    DeleteProposalModalComponent,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class SharedModule {}
